<template>
  <div id="divContractsWrapper" class="static-page-holder">
    <VueWrapperNarrow>
      <VueHeadline class="contracts-headline" level="2" weightLevel="3">{{
        items[0].header
      }}</VueHeadline>
      <VueText class="contract-body" weightLevel="1" sizeLevel="4" color="grey-40">
        {{ items[0].text }}</VueText
      >
    </VueWrapperNarrow>
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';
import StaticContent from '@/services/Api/staticContents';

export default {
  name: 'Contracts',
  components: { VueText, VueHeadline, VueWrapperNarrow },
  data() {
    return {
      items: [
        {
          header: 'Kullanıcı Sözleşmesi',
          text: '',
        },
      ],
    };
  },
  created() {
    StaticContent.getStaticContent('Sozlesme').then(res => {
      if (res.data.Data) {
        for (let item of this.items) {
          item.text = res.data.Data.content;
        }
      }
    });
  },
};
</script>

<style scoped lang="scss">
.contracts-headline {
  padding: palette-space-level('60') palette-space-level('20');
}

.contract-body {
  line-height: 1.71;
  font-weight: 300;
}
</style>
